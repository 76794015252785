import React, { useState, useEffect, createContext, useRef } from 'react';
import { Link, Router, Redirect } from '@reach/router';
import { Layout, Menu } from 'antd';
import {UserOutlined, CalendarOutlined} from '@ant-design/icons'
import styled from  'styled-components';

import CTS from './cts';
import Eventi from './eventi';
import SingoloEvento from './eventi/singolo-evento';
import {ScrollProvider} from '../../hooks/useScroller';
import cm from '../../controllers';

const LogoContainer = styled.div`
  background: #fff;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  text-align:center;
  margin-bottom: 40px;
  img {
    width: 100%;
  }
`;



const {Sider, Content} = Layout;

const Sidebar = () => {
  const [isCollapsed, setCollapsed] = useState(true);



  return <Sider
    collapsible
    collapsed={isCollapsed}
    onCollapse={() => setCollapsed(!isCollapsed)}
  >
    <LogoContainer><img src={`/logo${!isCollapsed ? '_full' : ''}.png`} /></LogoContainer>
    <Menu theme="dark">
      <Menu.Item icon={<CalendarOutlined />}>
        <Link to="/dashboard/eventi">
          Eventi
        </Link>
      </Menu.Item>
      <Menu.Item icon={<UserOutlined />}>
        <Link to="/dashboard/cts">
          CTS
        </Link>
      </Menu.Item>
    </Menu>
  </Sider>
}


const Dashboard = () => {
  const [r, setR] = useState(null);
  
  useEffect(() => {
    cm.controllers.websocket.connect();
    // cm.controllers.chat.connect();
  }, []);

  return <ScrollProvider value={r}>
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <div className="ant-layout-content" ref={(newRef) => {setR(newRef)}}
      style={{
        // scrollBehavior: 'smooth'
      }}>
        <Router>
          <Redirect from="/" to="eventi" />
          <CTS path="cts" />
          <SingoloEvento path="eventi/:id/*" />
          <Eventi path="eventi" /> 
        </Router>
      </div>
    </Layout>
    </ScrollProvider>
};

export default Dashboard;