import { reaction } from 'mobx';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DashboardMap from '../../../../components/dashboard/map';
import ByType from '../../../../components/segnalazioni/byType';
import RequiringAttention from '../../../../components/segnalazioni/requiring-attention';
import { InCorso, Risolte, Totali } from '../../../../components/segnalazioni/statistics';
import TeamListWidget from '../../../../components/teams/list';
import useDrawer from '../../../../hooks/useDrawer';

import cm from '../../../../controllers';
import { Button, Card, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import { SettingOutlined } from '@ant-design/icons';
import {navigate} from '@reach/router';
import moment from 'moment';
import SegnalazioniTable from '../../../../components/segnalazioni/table';
import Chat from '../../../../components/chat';

const Grid = styled.div`
  display: grid;
  padding: 0 2% 2% 2%;
  height: calc(100% - 72px);
  width: 100%;
  grid-gap: 2%;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(7, 1fr);
`;


const SelectTappe = observer(() => {

  reaction(
    () => cm.controllers.tappe.list,
    () => {
      console.log(cm.controllers.tappe.list)
      const t = cm.controllers.tappe.list
        .slice()
        .sort((a, b) => (
          Math.abs(Date.now() - new Date(a.data)) - Math.abs(Date.now() - new Date(b.data))
        ))[0]
      cm.controllers.tappe.current = t;
    }
  )

  return <Select value={cm.controllers.tappe.current?.id} onChange={(val) => cm.controllers.tappe.current = cm.controllers.tappe.map[val]}>
    {cm.controllers.tappe.list.map(tappa => <Select.Option key={tappa.id} value={tappa.id}>
      {new Date(tappa.data).toLocaleDateString()}
    </Select.Option>)}
  </Select>
})



const Gestione = (props) => {
  const [Drawer, open, close] = useDrawer();


  useEffect(() => {
    let r = [];
    reaction(
      () => cm.controllers.eventi.current,
      (current, reaction) => {
        if (cm.controllers.eventi.current) {
          if (!cm.controllers.tappe.fetched) {
            cm.controllers.tappe.fetch();
          }
          if (!cm.controllers.teams.fetched) {
            cm.controllers.teams.fetch();
          }
          if (!cm.controllers.cts.fetched) {
            cm.controllers.cts.fetch();
          }
          if (!cm.controllers.segnalazioni.fetched) {
            cm.controllers.segnalazioni.fetch()
          }
        }
      },
      {fireImmediately: true}
    );
    return () => {
      r.forEach(item => item.dispose());
    }
  }, [])

  return <>
  
  <header style={{ 
    padding: '20px 2% 20px 2%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff'
  }}>
    <Space>
      Tappa corrente: <SelectTappe />
    </Space>  
    <Button onClick={() => navigate('impostazioni')} icon={<SettingOutlined />}>Impostazioni</Button>
  </header>
  <Grid>
    <Totali openDrawer={open} />
    <Risolte openDrawer={open}  />
    <InCorso openDrawer={open}  />
    <ByType rows={3} title="Segnalazioni in corso" openDrawer={open} />
    <TeamListWidget rows={6} title="Teams" openDrawer={open}/>
    <DashboardMap cols={3} rows={6} openDrawer={open} />
    <RequiringAttention title="Richiedono attenzione" rows={4} openDrawer={open} />
    <Chat />
  </Grid>
  <Drawer width="50%" close={close} closeAll={close}/>
  </>
};

export default Gestione;