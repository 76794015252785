import Controller from '../utils/Controller';
import { decorate, computed, action, observable, reaction } from 'mobx';
import { notification } from 'antd';

export const SEGNALAZIONI_COLORS = {
  rosso: {
    background: 'rgb(219, 58, 52)',
    color: 'white'
  },
  giallo: {
    background: 'rgb(255, 200, 87)',
    color: '#333'
  },
  blu: {
    background: 'rgb(61, 165, 217)',
    color: 'white'
  },
  bianco: {
    background: 'rgb(240, 240, 240)',
    color: '#333'
  }
}

class Segnalazioni extends Controller {
  constructor(options, manager) {
    super(options, manager);
    this.fetched = false;
    this.list = [];
  }
 
  get map() {
    return this.list
      .reduce((agg, curr) => {
        agg[curr.id] = curr;
        return agg;
      }, {});
  }

  async init() {
    super.init();

    this.manager.controllers.websocket.addEventListener('segnalazione_created', this.add.bind(this));
    this.manager.controllers.websocket.addEventListener('segnalazione_updated', this.replace.bind(this));
  }

  get risolte () { 
    return (this.list ||  [])
      .filter(segnalazione => segnalazione.resolved)
  }

  get inCorso () {
    return (this.list ||[])
      .filter(segnalazione => !segnalazione.resolved)
  }

  get name() {
    return 'segnalazioni'
  }

  get priority() {
    return 1003;
  }

  add(segnalazione) {
    notification.open({
      message: 'Nuova segnalazione',
      description: `Nuovo codice ${segnalazione.codice} ricevuto`
    })
    this.list = [...this.list, segnalazione];
  }

  async getAssignedTeams(id) {
    return await this.manager.controllers.teams.getByAssignement(id)
  }

  async get(id) {
    if (this.map[id]) {
      return this.map[id];
    } else {
      const currentEvent = this.manager.controllers.eventi.current;
      if (currentEvent) {
        const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/segnalazioni/${id}`);
        if (!result.Item) return;
        this.list = [...this.list, result.Item];
        return result.Item;
      }
    }
  }

  async delete(id) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.delete(`/eventi/${currentEvent.id}/segnalazioni/${id}`);
      this.list = this.list.filter(segnalazione => segnalazione.id !== id);
    }
  }

  async fetch() {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.get(`/eventi/${currentEvent.id}/segnalazioni`) 
      this.list = result.Items;
      this.fetched = true;      
    }
  }

  async updateNote(id, note) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.put(`/eventi/${currentEvent.id}/segnalazioni/${id}/note`, {
        body: {note}
      }) 
      this.replace(id, result.Item);
    }
  }

  find(id) {
    return this.list.findIndex(segnalazione => segnalazione.id === id);
  }

  replace(id, valore) {
    const temp = [...this.list];
    const idx = this.find(id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.list = temp;
    }
  }

  replace(valore) {
    const temp = [...this.list];
    const idx = this.find(valore.id);
    if (idx !== -1) {
      temp[idx] = valore;
      this.list = temp;
    }
  }

  async resolve(id) {
    const currentEvent = this.manager.controllers.eventi.current;
    if (currentEvent) {
      const result = await this.manager.controllers.api.post(`/eventi/${currentEvent.id}/segnalazioni/${id}/resolve`)
      this.replace(id, result)
    }
  }
  
}

export default decorate(Segnalazioni, {
  list: observable,
  map:computed,
  risolte: computed,
  inCorso: computed,
  fetch: action,
  resolve: action
});