import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Maps from 'google-map-react';
import CarMarker from '../car-marker';
import MarkerSegnalazioni from '../marker-segnalazioni';

import Widget from './widget';

import cm from '../../controllers';
import { observer } from 'mobx-react';
import SegnalazioniDetail from '../segnalazioni/detail';
import { reaction } from 'mobx';
import TeamDetail from '../teams/detail';


const DashboardMap = observer((props) => {
  const [google, setGoogle] = useState(null);
  const layers = useRef();


  useEffect(() => {
    let r;
    reaction(
      () => cm.controllers.tappe.current,
      (tappa, reaction) => {
        r = reaction;
        if (layers.current) {
          layers.current.forEach(layer => layer.setMap(null))
        }
        layers.current = []; 
        if (!tappa || !tappa.settori || tappa.settori.length === 0 || !google) return;
       
        const config = cm.controllers.config.get('amplify').Storage;
        const {settori} = tappa;
        settori
          .map(settore => {
            return `https://s3.${config.AWSS3.region}.amazonaws.com/${config.AWSS3.bucket}/public/${settore.file}`;
          })
          .forEach((url) => {
            layers.current.push(
              new google.maps.KmlLayer(url, {
                map: google.map,
                suppressInfoWindows: false,
                preserveViewport: false,
              })
            )
          })
      },
      {fireImmediately: true}
    );
    
    return () => r.dispose();

  }, [google]);

  const handleApiLoaded = useCallback((googleMaps) => {
    setGoogle(googleMaps);
  }, []);

  return <Widget {...props} noPadding>
    <Maps
      bootstrapURLKeys={{ key: 'AIzaSyDtTtWDz9AynV0hBW2_WVZqfKOaoHpxrsc' }}
      defaultCenter={{lng: 10.2157, lat: 45.5318 }}
      defaultZoom={11}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleApiLoaded}
    >
      { 
        cm.controllers.segnalazioni.list.filter(s => !s.resolved).map(item => {
          return <MarkerSegnalazioni key={`marker-segn-${item.id}`} coinvolti={item.coinvolti} onClick={() => {
            props.openDrawer(
            'Dettagli segnalazione',
            SegnalazioniDetail,
            {id: item.id})
          }} codice={item.codice} {...item.coords} key={item.id} />
        })
      }
      {
        cm.controllers.teams.list.filter(item => item.ultimaPosizione).map(item => {
          return <CarMarker key={`marker-auto-${item.id}`} onClick={() => props.openDrawer(
            'Dettagli team',
            TeamDetail,
            {id: item.id}
          )} tipo={item.tipo} codice={item.codice} lat={item.ultimaPosizione.latitude} lng={item.ultimaPosizione.longitude} />
        })
      }
    </Maps>

  </Widget>
});

export default DashboardMap;