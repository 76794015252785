import { CloudDownloadOutlined, EyeFilled } from '@ant-design/icons';
import { List, Avatar, Space, Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import cm from '../../controllers'
import withS3Src from '../../hoc/withS3Src';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 20px 0;
  border-bottom: 1px solid #ececec;
  h2 {
    color: white;
    margin: 0;
  } 
`;


const Logo = withS3Src(Avatar);

const Reports = observer(() => {
  return <List
    dataSource={cm.controllers.eventi.list.filter(item => item.report)}
    renderItem={(item) => <Container>
      <Space size={26}>
        <Logo src={item.logo} size={70}/>
        <div>
          <h2>{item.titolo}</h2>
          <span>Dal {new Date(item.startDate).toLocaleDateString()} al {new Date(item.endDate).toLocaleDateString()}</span>
        </div>
      </Space>
      <Space>
        <Button onClick={() => {
          window.open(item.report);
        }} icon={<EyeFilled />}>Visualizza report</Button>
        {
          item.csv ?
          <Button onClick={() => {
            window.open(item.csv);
          }} icon={<EyeFilled />}>Scarica csv</Button> :''
        }
      </Space>
      </Container>}
  />;
});

export default Reports;